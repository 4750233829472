var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { fontWeight, space, themeGet } from 'styled-system';
import { CssGrid } from '../../common/components/CssGrid';
import RichTextBlock, { font } from '../../common/components/RichText';
import InspirationalTeaserItem from './InspirationalTeaserItem';
var teaserStyles = {
    gridGap: ['sp_16', 'sp_24', null, 'sp_32'],
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
    bg: ['sys.neutral.background.weaker', 'sys.neutral.background.default'],
};
var Title = styled(RichTextBlock)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    color: ", ";\n    ", ";\n    ", ";\n  }\n  ", ";\n"], ["\n  p {\n    color: ", ";\n    ", ";\n    ", ";\n  }\n  ", ";\n"])), themeGet('colors.sys.neutral.text.strong'), font(['fs_16', 'fs_18', null, 'fs_20'], ['lh_20', 'lh_24', null, 'lh_28']), fontWeight, space);
var titleStyles = {
    mb: ['sp_16', 'sp_20', 'sp_24', 'sp_32'],
    fontWeight: 'bold',
};
var isMultipleOfThree = function (n) { return n % 3 === 0; };
var CommonInspirationalTeaser = function (_a) {
    var titleText = _a.titleText, isTitleCenterAligned = _a.isTitleCenterAligned, items = _a.items;
    return (React.createElement(React.Fragment, null,
        titleText && (React.createElement(Title, __assign({}, titleStyles, (isTitleCenterAligned && { textAlign: 'center' }), { dangerouslySetInnerHTML: { __html: titleText } }))),
        React.createElement(CssGrid, __assign({}, teaserStyles), items.map(function (item, index) { return (React.createElement(InspirationalTeaserItem, __assign({}, item, { key: index, isEnhanced: isMultipleOfThree(index + 1), index: index }))); }))));
};
export default CommonInspirationalTeaser;
var templateObject_1;
